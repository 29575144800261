const imagesPrefix =
  "https://d2u61031vokrmr.cloudfront.net/webp/thumbnails/events";


export default [
  {
    title: "Events",
    key:5,
    description: "Events Thumbnails",
    items: [
      {
        image: `${imagesPrefix}/CUSTOM.webp`,
        name: "Custom",
        
        route: "https://youtu.be/ILAhyg6bYng",
        videoId: "ILAhyg6bYng",
      },
      {
        image: `${imagesPrefix}/ADESTRA+20TH+ANIVERSARY.webp`,
        name: "Adestra",
        
        route: "https://youtu.be/FJwzSUlitp0",
        videoId: "FJwzSUlitp0",
      },
      {
        image: `${imagesPrefix}/SMI+MARATIME.webp`,
        name: "Maritime",
        
        route: "https://youtu.be/7KfOm5vC9ak",
        videoId: "7KfOm5vC9ak",
      },
      {
        image: `${imagesPrefix}/VAPE+SHOW+DUBAI.webp`,
        name: "Myle",
        
        route: "https://youtu.be/oN-7iJ7dINY",
        videoId: "oN-7iJ7dINY",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_EV+-+Bima.webp`,
        name: "BIMA",
        
        route: "https://youtu.be/QQoLefOqDoQ",
        videoId: "QQoLefOqDoQ",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_EV+-+Cafe+culture-01.webp`,
        name: "Cafe Culture",
        
        route: "https://youtu.be/W-72WLps1mM",
        videoId: "W-72WLps1mM",
      },
      // {
      //   image: `${imagesPrefix}/dfp-thumbnails_EV+-+MAritine-01.webp`,
      //   name: "Maritime",
        
      //   route: "https://youtu.be/_WpO3-QjINM",
      //   videoId: "_WpO3-QjINM",
      // },
      {
        image: `${imagesPrefix}/dfp-thumbnails_EV+-+Cosun-01.webp`,
        name: "Cosun Beet",
        
        route: "https://youtu.be/peRrOxSpmCY",
        videoId: "peRrOxSpmCY",
      },
      // {
      //   image: `${imagesPrefix}/dfp-thumbnails_Myle+vape+vd+2-01.webp`,
      //   name: "Myle Vape",
        
      //   route: "https://youtu.be/hn68E6YO2sk",
      //   videoId: "hn68E6YO2sk",
      // },
      {
        image: `${imagesPrefix}/dfp-thumbnails_EV+-+Hackathon-01.webp`,
        name: "Hackathon",
        
        route: "https://youtu.be/CAWZ5bowA1A",
        videoId: "CAWZ5bowA1A",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_Muslim+News-01.webp`,
        name: "The Muslim News",
        
        route: "https://youtu.be/II2u7I3jRfY",
        videoId: "II2u7I3jRfY",
      },
      // {
      //   image: `${imagesPrefix}/dfp-thumbnails_EV+-Profit-01.webp`,
      //   name: "Profitero",
        
      //   route: "https://youtu.be/sKJxueEw6y0",
      //   videoId: "sKJxueEw6y0",
      // },
      {
        image: `${imagesPrefix}/dfp-thumbnails_EV+-+upland-01.webp`,
        name: "Upland",
        
        route: "https://youtu.be/VGsrv_b3mmg",
        videoId: "VGsrv_b3mmg",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_ev+-+Raza+Hooda-01.webp`,
        name: "Reza Hooda",
        
        route: "https://youtu.be/x-UCazqltck",
        videoId: "x-UCazqltck",
      },
    ],
  },
];
