const imagesPrefix =
  "https://d2u61031vokrmr.cloudfront.net/webp/thumbnails/talking-heads";


export default [
  {
    title: "Talking Heads",
    key: 10,
    description: "Talking Heads Thumbnails",
    items: [
      {
        image: `${imagesPrefix}/VAPE+LOCAL.webp`,
        name: "Vape Local",
        
        route: "https://youtu.be/JCPg5Ugsi40",
        videoId: "JCPg5Ugsi40",
      },
      {
        image: `${imagesPrefix}/UNIVERSITY+HOSPITAL+SOUTHAMPTON.webp`,
        name: "University",
        
        route: "https://youtu.be/FJTel7rMsPM",
        videoId: "FJTel7rMsPM",
      },
      {
        image: `${imagesPrefix}/SYDNEY+USHURNE.webp`,
        name: "Sydney",
        
        route: "https://youtu.be/Vp-d9xwi15A",
        videoId: "Vp-d9xwi15A",
      },
      {
        image: `${imagesPrefix}/ADESTRA+TESTIMONIAL.webp`,
        name: "Adestra",
        
        route: "https://youtu.be/yCvjNsBaULM",
        videoId: "yCvjNsBaULM",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_ring+central+AI-01.webp`,
        name: "RingCentral AI",
        
        route: "https://youtu.be/puYA_-Kbes4",
        videoId: "puYA_-Kbes4",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_ring+-+central++Pace+of+Change-01.webp`,
        name: "RingCentral Pace of Change",
        
        route: "https://youtu.be/Kp8RDvLqzlQ",
        videoId: "Kp8RDvLqzlQ",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_Total+energy+vd+2-01.webp`,
        name: "TotalEnergies",
        
        route: "https://youtu.be/8CfOM1NydQ8",
        videoId: "8CfOM1NydQ8",
      },
      {
        image: `${imagesPrefix}/dfp-thumbnails_British+CBD-01.webp`,
        name: "British CBD",
        
        route: "https://youtu.be/Wdbli1Odb4k",
        videoId: "Wdbli1Odb4k",
      },
      // {
      //   image: `${imagesPrefix}/dfp-thumbnails_Cosun+Beet+%40+FIE+2022-01.webp`,
      //   name: "Cosun Beet",
        
      //   route: "https://youtu.be/NEfLggr-900",
      //   videoId: "NEfLggr-900",
      // },
      {
        image: `${imagesPrefix}/dfp-thumbnails_Employee+and+Family+Values+Catalyst+Care+Group-01.webp`,
        name: "Employee and Family Values",
        
        route: "https://youtu.be/YhrG-A8iNsU",
        videoId: "YhrG-A8iNsU",
      },
      {
        image: `${imagesPrefix}/1+-+RETAIL+.webp`,
        name: "RingCentral Retail Challenges",
        
        route: "https://youtu.be/kcHyAKgJuQM",
        videoId: "kcHyAKgJuQM",
      },
      // {
      //   image: `${imagesPrefix}/dfp-thumbnails_Let+Pass+-Are+security+tools+able+to+adequately+help+control+this_-01.webp`,
      //   name: "LastPass Security Tools",
        
      //   route: "https://youtu.be/SltKAqjFVg8",
      //   videoId: "SltKAqjFVg8",
      // },
    ],
  },
];
